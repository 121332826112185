import { template as template_d6e98686ad8144288131e70cd3e42e30 } from "@ember/template-compiler";
const WelcomeHeader = template_d6e98686ad8144288131e70cd3e42e30(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
