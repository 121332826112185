import { template as template_92b459e411f447f6959c38e199cc9e0a } from "@ember/template-compiler";
const FKLabel = template_92b459e411f447f6959c38e199cc9e0a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
