import { template as template_fe01ba6e21444d67aa6b605a9a0f2d9a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_fe01ba6e21444d67aa6b605a9a0f2d9a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
