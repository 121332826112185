import { template as template_a5b8bc9a93fd4000b4f667789d22e894 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_a5b8bc9a93fd4000b4f667789d22e894(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
