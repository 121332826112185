import { template as template_6f6ceb6e9eb14fd0a8af29c5e8ec72e9 } from "@ember/template-compiler";
const EmptyState = template_6f6ceb6e9eb14fd0a8af29c5e8ec72e9(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
