import { template as template_a4dee3dafe02499d922e5635e3fc2b8f } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuCopyLinkButton = template_a4dee3dafe02499d922e5635e3fc2b8f(`
  <DButton
    class="post-action-menu__copy-link"
    ...attributes
    @action={{@buttonActions.copyLink}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.copy_action"}}
    @title="post.controls.copy_title"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuCopyLinkButton;
